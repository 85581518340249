import styled, { version } from "styled-components";
import { typography } from '../../styles/mixins';
import { Link } from 'react-router-dom';


export const FooterWrapper = styled.div`
    background-color: rgba(0, 0, 0, 0.85);
    /* background-color: var(--color-neutral-2-grey); */
    /* background-color: rgba(112, 108, 97, 0.85); */
    height: 10rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem;
    width: 100%;
    /* display: none; */

    @media (min-width: 744px) {
        padding: 2rem;
        height: 12rem;

    }
`

export const Brand = styled.div`
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    /* gap: 2rem; */
    ${typography('p6', 'header')}

    @media (min-width: 744px) {
    }

    @media (min-width: 1440px) {
    }
`

export const CompanyName = styled.h1`
    color: var(--color-primary);
    ${typography('p3', 'header')};
    font-weight: bold;

`
export const StyledLogoLink = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    `

export const StyledLogo = styled.img`
  height: 2.3rem;
  width: 2.3rem;
`

export const NavList = styled.ul`
    list-style: none;
    color: var(--color-neutral-4-white);
    padding-left: 0.4rem;

`
export const NavItem = styled.li`
    ${typography('p5', 'header')};
    padding-bottom: 0.3rem;

    @media (min-width: 744px) {
        padding-bottom: 0.85rem;
    }

`

export const NavLink = styled.a`
  text-decoration: none;
  color: #fff;
  `