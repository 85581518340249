import styled, { css } from "styled-components";
import { typography } from '../../styles/mixins';


export const primaryStyle = css`
    background-color: var(--color-primary);
    color: var( --color-text-on-primary);
    border: none;
    cursor: pointer;    cursor: pointer;
`

export const secondaryStyle = css`
    background-color: var( --color-background-light);
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    cursor: pointer;
`

export const tertiaryStyle = css`
    color: var(--color-primary);
    background-color: transparent;
    border: none;
    text-decoration: underline;
    padding: 0;
    width: auto; 
    cursor: pointer;
    
    &:hover {
        text-decoration: none; 
    }
`
export const disabledStyle = css`
    background-color: var( --color-neutral-3-light-grey);
    color: var( --color-text-disabled);
    cursor: not-allowed;
    pointer-events: none;
`
export const StyledButton = styled(({ active, secondary, tertiary, ...rest }) => (
    <button {...rest} />
))`
    border-radius: 1.375rem;
    cursor: pointer;
    ${typography('p4', 'paragraph')};
    font-weight: bold;
    padding: 0.5rem;
    width: 17rem;

    ${(props) =>
        props.disabled
            ? disabledStyle
            : props.tertiary
            ? tertiaryStyle
            : props.secondary
            ? secondaryStyle
            : primaryStyle}    


    @media (min-width: 744px) {
        ${typography('p3')};
        font-weight: bold;
        padding: 1rem;
        width: 17rem;

    }
    @media (min-width: 1440px) {
        ${typography('p2')};
        font-weight: bold;
        width: 20rem;
    }
`


