import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
// import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { NavBar } from './components/NavBar/NavBar'
import { WelcomePage } from './components/WelcomePage/WelcomePage'
import { AboutUs } from './components/AboutUs/AboutUs'
import { ComparisonPage } from './components/ComparisonPage/ComparisonPage'
import { ResultsPage } from './components/ResultsPage/ResultsPage'
import { SummaryPage } from './components/SummaryPage/SummaryPage'
import { Footer } from './components/Footer/Footer'
import { NotFound } from './components/NotFound/NotFound'
import { Blog } from './components/Blog/Blog'
import { ComparisonModeProvider } from './context/ComparisonModeContext'
import { BackToTop } from './ui/BackToTop/BackToTop'

export const  App = () => {

  // const reducer = combineReducers({
  //   user: user.reducer,
  //   events: events.reducer
  // });

  // const store = configureStore({ reducer })

  // const location = useLocation();

  // useEffect(() => {
  //   window.gtag('config', 'G-K2J3BVJM3F', {
  //     page_path: location.pathname,
  //   });
  // }, [location]);
  // console.log(window.gtag);


  return (
    // <Provider store={store}>
    <ComparisonModeProvider>
       <BrowserRouter basename={process.env.PUBLIC_URL}>
       <NavBar />
       <main>
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/comparison" element={<ComparisonPage />} />
          <Route path="/results" element={<ResultsPage />} />
          <Route path="/summary" element={<SummaryPage />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
       </main>
       
      <Footer />
      <BackToTop/>
      </BrowserRouter>
    {/* // </Provider> */}
    </ComparisonModeProvider>
      

  );
}

export default App;
