import React, { useState } from 'react';
import { StyledBurger, Nav, NavList, NavItem, NavLink } from './HamburgerMenu.styles.js';

export const HamburgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    const goToWelcomePage = () => {
    };
    const goToAboutUsPage = () => {
    };

    return (
        <>
            <div>
                <StyledBurger onClick={toggleMenu}/>
                <Nav isOpen={isOpen ? 'true' : undefined}>           
                    <NavList>
                        <NavItem><NavLink href="/" onClick={goToWelcomePage}>Hem</NavLink></NavItem>
                        <NavItem><NavLink href="/aboutus" onClick={goToAboutUsPage}>Om Oss</NavLink></NavItem>
                        <NavItem><NavLink href="/blog">Blog</NavLink></NavItem>
                        {/* <NavItem><NavLink href="#table">Comparison</NavLink></NavItem> */}
                    </NavList>
                </Nav>
            </div>
        </>
    )
}

