import styled from 'styled-components';
import { typography } from '../../styles/mixins';


export const SummaryPageTitle = styled.h1`
    ${typography('h3', 'header')};

    @media (min-width: 744px) {
        ${typography('h1')};
        align-self: center;
    }
`
export const SelectedCarSummaryTitle = styled.h1`
    /* color: var(--color-neutral-1-black);
    background-color: var(--color-neutral-3-light-grey); */
    color: var(--color-neutral-4-white);
    background-color: var(--color-neutral-2-grey);
    display: flex;
    align-self: flex-start;
    max-width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    ${typography('h4', 'header')};

    @media (min-width: 744px) {
        ${typography('h3')};
        align-self: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    @media (min-width: 1440px) {
        ${typography('h2')};
        /* padding-top: 1.375rem;
        padding-bottom: 1.375rem; */
    }
`


export const InformationAnnouncement = styled.h3`
        color: var(--color-neutral-1-black);
        ${typography('p3', 'paragraph')};
        line-height: normal;
        font-weight: bold;
        margin-bottom: 1rem;

        @media (min-width: 744px) {
        ${typography('p2')};
        font-weight: bold;
        }

        @media (min-width: 1440px) {
            ${typography('p1')};
            font-weight: bold;
        }
`


export const SummaryStats = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1.2rem;
`
export const Stat = styled.h3`
    ${typography('p4', 'paragraph')};
    margin-bottom: 0.5rem;
    display: flex;
    justify-self: center;
    gap: 0.5rem;

    span {
        font-weight: bold;
        color: var(--color-neutral-2-grey);
    }

    @media (min-width: 744px) {
        ${typography('p3')};
    }

    @media (min-width: 1440px) {
        ${typography('p2')};
    }
`
export const SummaryTotal = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 2.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: var(--color-neutral-3-light-grey); 
    border-radius: 0.25rem;


    @media (min-width: 744px) {
        min-width: 700px;
    }

    @media (min-width: 1440px) {
        min-width: 1050px;
    }

`
export const SummaryTotalResultContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;


 
`

export const SummaryTotalResult = styled.h4`
    ${typography('p3', 'header')};

    span {
            font-weight: lighter;
        }

    @media (min-width: 744px) {
        ${typography('p2', 'header')};

    }

    @media (min-width: 1440px) {
    

    }

`
export const SummaryTotalResultTitle = styled.h3`
        ${typography('p2', 'header')};
        font-weight: bold;
        margin-bottom: 1rem;

       
`
export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
   
`