import React from 'react';
import { Menu, Brand, StyledLogoLink, CompanyName, StyledLogo, NavList, NavListMobile, NavListNonMobile, NavItem, NavLink } from './NavBar.styles';
import logo from '../../assets/CarLogo.svg';
import { HamburgerMenu } from '../../ui/HamburgerMenu/HamburgerMenu.js';

export const NavBar = () => {

    const goToWelcomePage = () => {
      };

    const goToAboutUsPage = () => {

    };

      const goToBlogPage = () => {
    };

    return (
        <>
        <Menu>
            <Brand>
                <StyledLogoLink to="/" onClick={goToWelcomePage}>
                    <StyledLogo src={logo} alt="Logo" />
                    <CompanyName>Min Bilkostnad</CompanyName>  
                </StyledLogoLink>   
            </Brand>
            <NavListMobile>
                <HamburgerMenu />
            </NavListMobile>
            <NavListNonMobile>
                <NavList>
                    <NavItem><NavLink href="/"onClick={goToWelcomePage}>Hem</NavLink></NavItem>
                    <NavItem><NavLink href="/aboutus" onClick={goToAboutUsPage}>Om Oss</NavLink></NavItem>
                    <NavItem><NavLink href="/blog" onClick={goToBlogPage}>Blog</NavLink></NavItem>
                    {/* <NavItem><NavLink href="#table">Comparison</NavLink></NavItem> */}
                </NavList>
            </NavListNonMobile>
        </Menu>         
        </>
     
    )
}