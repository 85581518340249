import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { typography } from '../../styles/mixins';

export const Title = styled.h1`
    ${typography('h5', 'paragraph')};

    @media (min-width: 744px) {
        ${typography('h4')};

    }
`

export const Information = styled.p`
    ${typography('p4', 'paragraph')};
    
    @media (min-width: 744px) {
        ${typography('p2')};
        font-weight: normal;
    }

`
export const NavLink = styled(Link)`
    color: var(--color-text-on-background-light);
    ${typography('p4', 'paragraph')};
    text-decoration: underline;
    padding: 2rem 0;
    cursor: pointer;

    &:hover {
        color: var(--color-neutral-2-grey);
    }


    @media (min-width: 744px) {
        ${typography('p2')};
        font-weight: normal;
    }

`