import React from 'react';
import { Wrapper } from '../Wrapper/Wrapper';

export const Blog = ({ title, content }) => {
    return (

        <Wrapper>
            <div className="blog-post">
                <h1>Kommer snart!</h1>
                <h2>{title}</h2>
                <p>{content}</p>
            </div>
{/*         
            <h1>Stort Test 2024: Jämförelse av Värdeminskning på 10 Populära Laddhybrider i Sverige</h1>
            <p>Laddhybrider har blivit alltmer populära på den svenska marknaden tack vare sina låga utsläpp, lägre driftskostnader och statliga subventioner. Men för bilköpare är det också viktigt att överväga hur mycket bilen kommer att minska i värde över tid. Värdeminskningen kan ha en stor påverkan på den totala kostnaden för att äga bilen. I denna artikel jämför vi värdeminskningen på 10 av de mest populära laddhybriderna i Sverige, baserat på deras inköpspris för 2024 års modell och nuvarande värde på 2021 års modell.</p>
            <br/>
            <p>Testet är enkelt, vi har tittat på vad en genomsnittlig bil med 2024 års modell kostar idag och jämfört den med vad motsvarande modell kostar för modellår 2021. På så sätt har vi beräknat den förväntade värdeminskningen över 3 års tid.</p> */}
        </Wrapper>
    )
}