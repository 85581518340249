import React from 'react';
import { FooterWrapper, Brand, StyledLogoLink, CompanyName, StyledLogo, NavLink, NavList, NavItem } from './Footer.styles';
import logo from '../../assets/CarLogo.svg';


export const Footer = () => {
    
    const goToWelcomePage = () => {
    };

    return (
        <FooterWrapper>
            <Brand>
                <StyledLogoLink to="/" onClick={goToWelcomePage}>
                    <StyledLogo src={logo} alt="Logo" />
                    <CompanyName>Min Bilkostnad</CompanyName>  
                </StyledLogoLink>   
            </Brand>        
            <NavList>
                    <NavItem><NavLink href="/aboutus">Om Oss</NavLink></NavItem>
                    <NavItem><NavLink href="/blog">Blog</NavLink></NavItem>
                    {/* <NavItem><NavLink href="#table">Comparison</NavLink></NavItem> */}
            </NavList>        
        </FooterWrapper>
    )
}