import styled from 'styled-components';
import { typography } from '../../styles/mixins';

export const LoanCostInfo = styled.h3`
    color: var(--color-neutral-1-black);
    /* display: flex; */
    align-self: flex-start;
    max-width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    ${typography('h6', 'paragraph')};
    font-weight: normal;
    line-height: normal;

    @media (min-width: 744px) {
        ${typography('h5')};
        font-weight: normal;
        align-self: center;
        /* padding-top: 1rem;
        padding-bottom: 1rem; */
    }

    @media (min-width: 1440px) {
        ${typography('h4')};
        font-weight: normal;
        /* padding-top: 1.375rem;
        padding-bottom: 1.375rem; */
    }
`

export const LoanQuestion = styled.h4` 
  color: var(--color-neutral-1-black);
    display: flex;
    align-self: flex-start;
    max-width: 100%;
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    ${typography('h5', 'header')};
    font-weight: bold;


    @media (min-width: 744px) {
        ${typography('h4')};
        font-weight: bold;
        align-self: center;
        padding-bottom: 1rem;
    }

    @media (min-width: 1440px) {
        ${typography('h3')};
        font-weight: bold;
        padding-bottom: 1.375rem;
    }
`

export const LoanInstruction = styled.h4` 
  color: var(--color-neutral-1-black);
    display: flex;
    align-self: flex-start;
    max-width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;
    ${typography('h6', 'paragraph')};
    line-height: normal;
    /* font-weight: bold; */

    @media (min-width: 744px) {
        ${typography('h5')};
        font-weight: normal;
        align-self: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    @media (min-width: 1440px) {
        ${typography('h4')};
        font-weight: normal;
        padding-top: 1.375rem;
        padding-bottom: 1.375rem;
    }
`

export const LoanResult = styled.h4`
    color: var(--color-neutral-1-black);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-top: 1rem;
    ${typography('h6', 'paragraph')};
    font-weight: bold;
    background-color: var(--color-neutral-3-light-grey);

    @media (min-width: 744px) {
        ${typography('h5')};
        font-weight: bold;
        text-align: center;

        /* align-self: flex-start; */
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    @media (min-width: 1440px) {
        ${typography('h4')};
        font-weight: bold;
        padding-top: 1.375rem;
        padding-bottom: 1.375rem;
    }

`
export const LoanResultTotal = styled.h4`
    color: var(--color-neutral-1-black);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-top: 1rem;
    text-align: center;
    ${typography('h6', 'paragraph')};
    font-weight: normal;

    @media (min-width: 744px) {
        ${typography('h5')};
        font-weight: normal;
        text-align: center;

        /* align-self: flex-start; */
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    @media (min-width: 1440px) {
        ${typography('h4')};
        font-weight: normal;
        padding-top: 1.375rem;
        padding-bottom: 1.375rem;
    }

`

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
   
`
export const Slider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 0.75rem;
  border-radius: 0.25rem;
  /* background: #ddd; */
  background-color: var(--color-neutral-2-grey);
  outline: none;
  /* opacity: 0.7; */
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  @media (min-width: 744px) {
        height: 1rem;
    }

    @media (min-width: 1440px) {
        height: 1.2rem;
    }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 0.3rem solid var(--color-neutral-2-grey);
    background: var(--color-neutral-3-light-grey);
    cursor: pointer;

    @media (min-width: 744px) {
        width: 30px;
        height: 30px;
    }

    @media (min-width: 1440px) {
        width: 40px;
        height: 40px;
    }
  }

  &::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    /* background: #4caf50; */
    border: 0.3rem solid var(--color-neutral-2-grey);
    background: var(--color-neutral-3-light-grey);
    cursor: pointer;

    @media (min-width: 744px) {
        width: 30px;
        height: 30px;
    }

    @media (min-width: 1440px) {
        width: 40px;
        height: 40px;
    }
  }

`

export const Value = styled.span`
  ${typography('p3', 'paragraph')};
  font-weight: bold;


  @media (min-width: 744px) {
    ${typography('p2')};
    font-weight: bold;
    }

    @media (min-width: 1440px) {
        ${typography('p1')};
        font-weight: bold;
    }
` 
  