import React from 'react';
import { Wrapper } from '../Wrapper/Wrapper';
import { NavLink, Title, Information } from './NotFound.styles';

export const NotFound = () => {
    return (
        <Wrapper>
            <Title>404 - Page Not Found</Title>
            <Information>Tyvärr, sidan du letar efter finns inte.</Information>
            <NavLink to="/">Gå hem</NavLink>
        </Wrapper>
    )
}