import React from 'react';
import { Wrapper } from '../Wrapper/Wrapper';
// import { Link } from 'react-router-dom';

export const AboutUs = () => {

    return (
        <Wrapper>
            <h1>Om Min Bilkostnad</h1>
            <p>Allt började med en enkel fråga. 
            <br/><br/>
            Om jag köper den här bilen, hur mycket kan jag förvänta mig att den går ner i värde de kommande åren? 
            <br/><br/>
            Vi letade och letade utan att finna någon hemsida som kunde ge oss en konkret uppskattning. Därför har vi skapat minbilkostnad för dig. 
            <br/><br/>
            Hos oss kan du snabbt se en uppskattad värdeminskning av bilar samt jämföra olika modeller för att ta ett bättre ekonomiskt beslut. 
            <br/><br/>
            För vad de flesta inte tänker på inför ett bilköp är att värdeminskningen oftast är den största kostnaden för bilen.</p>
        </Wrapper>
        
    )
}

